import React from 'react'
import Table from "react-bootstrap/Table"
import Button from "react-bootstrap/Button"

const SearchResults = ({bookings, onShowReceipt}) => {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Email</th>
          <th>Phone</th>
          <th>Booking Date</th>
          <th>View</th>
        </tr>
      </thead>
      <tbody>
        {bookings.map(booking => (
          <tr key={booking.id}>
            <td>JDS_{booking.id}</td>
            <td>{booking.name}</td>
            <td>{booking.email}</td>
            <td>{booking.contact_no}</td>
            <td>{booking.booking_date}</td>
            <td>
              <Button
                variant="outline-primary" 
                size="sm"
                onClick={() => onShowReceipt(booking)}
              >
                <i className="icon-eye"></i>
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default SearchResults
