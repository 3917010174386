import React, { useRef, useState } from "react"
import domtoimage from "dom-to-image"
import Moment from "react-moment"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import styles from "./bookingReceipt.module.css"

const BookingReceipt = ({ receiptBackImage, booking }) => {
  const receiptRef = useRef()
  const downloadRef = useRef()
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  console.log(receiptBackImage, booking)

  const downloadReceipt = () => {
    if (isImageLoaded) {
      domtoimage.toJpeg(receiptRef.current, { quality: 1 }).then(dataUrl => {
        downloadRef.current.download = "receipt.jpeg"
        downloadRef.current.href = dataUrl
        downloadRef.current.click()
      })
    }
  }

  return (
    <Container>
      <div className={styles.downloadReceipt}>
        <div style={{ marginRight: 20, display: "inline-block" }}>
          Receipt No: JDS_{booking.id}
        </div>
        <Button variant="primary" onClick={downloadReceipt}>
          Download
        </Button>
      </div>
      <div className={styles.ReceiptContainer}>
        <div ref={receiptRef}>
          <div className={styles.ReceiptContainerBack}>
            <img
              src={receiptBackImage.childImageSharp.fixed.src}
              style={{ width: "100%" }}
              onLoad={() => setIsImageLoaded(true)}
            />
          </div>
          <div className={styles.ReceiptContainerInfo}>
            <div className={styles.billTag}>BILL TO</div>
            <Container>
              <Row className="mt-5 justify-content-center">
                <Col sm={4} xs={4}>
                  <div className={styles.TextContainer}>
                    <div className={styles.TextContainer__title}>Name</div>
                    <div className={styles.TextContainer__value}>
                      {booking.name}
                    </div>
                  </div>
                  <div className={styles.TextContainer}>
                    <div className={styles.TextContainer__title}>
                      Phone Number
                    </div>
                    <div className={styles.TextContainer__value}>
                      {booking.contact_no}
                    </div>
                  </div>
                  <div className={styles.TextContainer}>
                    <div className={styles.TextContainer__title}>Email</div>
                    <div className={styles.TextContainer__value}>
                      {booking.email}
                    </div>
                  </div>
                  <div className={styles.TextContainer}>
                    <div className={styles.TextContainer__title}>Person</div>
                    <div className={styles.TextContainer__value}>
                      {booking.adults} Adult {booking.childrens} Child
                    </div>
                  </div>
                </Col>
                <Col sm={4} xs={4}>
                  <div className={styles.TextContainer}>
                    <div className={styles.TextContainer__title}>
                      Receipt Number
                    </div>
                    <div className={styles.TextContainer__value}>
                      JDS_{booking.id}
                    </div>
                  </div>
                  <div className={styles.TextContainer}>
                    <div className={styles.TextContainer__title}>
                      Receipt Date
                    </div>
                    <div className={styles.TextContainer__value}>
                      <Moment format="DD-MM-YYYY">{booking.created_at}</Moment>
                    </div>
                  </div>
                  <div className={styles.TextContainer}>
                    <div className={styles.TextContainer__title}>
                      Check In Date
                    </div>
                    <div className={styles.TextContainer__value}>
                      {booking.booking_date}
                    </div>
                  </div>
                  <div className={styles.TextContainer}>
                    <div className={styles.TextContainer__title}>
                      Package Name
                    </div>
                    <div className={styles.TextContainer__value}>
                      {booking.tour_title}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            <hr />
            <Container>
              <div className={styles.totalAmount}>
                <div className={styles.totalAmount_title}>Total Amount</div>
                <div className={styles.totalAmount_amount}>
                  &#8377; {booking.amount} (INC. TAX)
                </div>
              </div>
              <div className={styles.thank_you}>Thank you!</div>
            </Container>
          </div>
        </div>
      </div>
      <a ref={downloadRef} href="#"></a>
    </Container>
  )
}

export default BookingReceipt
