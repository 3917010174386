import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import InputGroup from "react-bootstrap/InputGroup"
import FormLabel from "react-bootstrap/FormLabel"
import FormControl from "react-bootstrap/FormControl"
import Button from "react-bootstrap/Button"
import Spinner from "react-bootstrap/Spinner"
import StyledHero from "../components/StyledHero/StyledHero"
import axios from "../axios"
import SearchResults from "../components/Track/SearchResults"
import BookingReceipt from "../components/Track/BookingReceipt"

const Track = ({ data }) => {
  const [search, setSearch] = useState("")
  const [bookings, setBookings] = useState()
  const [booking, setBooking] = useState()
  const [isSearching, setIsSearching] = useState(false)
  const { heroImage, receiptImage } = data
  const getSearchResults = async () => {
    setIsSearching(true)
    setBookings(null)
    setBooking(null)
    const results = await axios.post("booking/v1/track", {
      search: search,
    })
    if (results.status === 200) {
      setBookings(results.data)
    }
    setIsSearching(false)
  }

  const handleShowReceipt = fetchedBooking => {
    setBookings(null)
    setBooking(null)
    setBooking(fetchedBooking)
  }


  const handleOnDownload  = () => {
    setBooking(null)
  }
 

  return (
    <Layout>
      <StyledHero image={heroImage.childImageSharp.fluid}>
        <h1 style={{ textTransform: "uppercase" }}>Booking Receipt</h1>
        <h4>Search by email/mobile to download booking receipt</h4>
      </StyledHero>
      <Container>
        <Row className="my-5 justify-content-center">
          <Col sm={6}>
            <FormLabel>Search by Email/Mobile</FormLabel>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Search"
                value={search}
                onChange={e => setSearch(e.target.value)}
                onKeyDown={e => {
                  if (e.key === "Enter") getSearchResults()
                }}
              />
              <InputGroup.Append>
                <Button
                  className="btn btn-primary btn-sm font-weight-bold"
                  onClick={getSearchResults}
                >
                  Search
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </Row>
        <Row sm={8} className="my-5">
          <Col>
            <div style={{ textAlign: "center" }}>
              {isSearching && <Spinner animation="border" variant="primary" />}
            </div>
            {bookings && bookings.length > 0 && (
              <SearchResults
                bookings={bookings}
                onShowReceipt={handleShowReceipt}
              />
            )}
            {booking && (
              <BookingReceipt
                receiptBackImage={receiptImage}
                booking={booking} 
                onDownload={handleOnDownload}
              />
            )}
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "Heros/contact-page.jpg" }) {
      id
      childImageSharp {
        fluid(maxWidth: 1920, maxHeight: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    receiptImage: file(relativePath: { eq: "backgrounds/receipt_back.png" }) {
      id
      childImageSharp {
        fixed(width: 1241, height: 1754) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
export default Track
